@import url("./Vendor/bootstrap.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");


*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.pointer{
  cursor: pointer;
}
.three-dot{
  align-items: center;
  height: 75vh;
  position: absolute; 
  width: calc( 100vw - 180px ); 
}
.mainContainer{
  min-height: 100vh;
  height: auto;
  padding-bottom: 30px;
}
.mainContent {
  margin: 0 60px 0 120px;
}
.container-fluid {
  width:100%;
  margin: 0 auto;
  padding: 0 !important;
}
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
  max-width: 100% !important;
}
h1 {
  font-family: 'Source Sans Pro', sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 500;
  text-align: center;
}
h2 {
  font-family: 'Source Sans Pro', sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
}
h3 {
  font-size: 1.17em;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  font-family: 'Source Sans Pro', sans-serif;
}
h4 {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-family: 'Source Sans Pro', sans-serif;
}
h5 {
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: 'Source Sans Pro', sans-serif;
}
h6 {
  font-size: 0.67em;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: 'Source Sans Pro', sans-serif;
}
input[type="radio"] {
  transform: scale(1.65);
  margin-right: 5px;
}
button {
  cursor: pointer;
}
p{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: 'Source Sans Pro', sans-serif;
}
input[type='checkbox'] {
  transform: scale(1.35);
  cursor: pointer;
}
.form-control{
  appearance: auto;
}
.text-xs{
  font-size: 14px;
}
label, input,select, ::placeholder{
  font-family: 'Source Sans Pro', sans-serif;
}
td, th{
  font-family: 'Source Sans Pro', sans-serif;
}
button, a{
  font-family: 'Source Sans Pro', sans-serif;
}
input{
  padding: 5px;
}
input:focus{
  outline: none;
}
.cursor-pointer{
  cursor: pointer;
}
.btn {
  align-self: center;
  background-color: #607afc !important;
  border: none;
  height: 35px;
  border-radius: 5px;
  padding: 0 20px;
  color: white;
  font-weight: bold;
  line-height: 35px;
  white-space: nowrap;
  font-size: 18px;
}
.btn:hover {
  color: white;
}
.btn-link {
  font-weight: 700;
  color: #5D78FF;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0 20px;
  font-size: 18px;
}
.link{
  color: #5D78FF;
  text-decoration: none;
  border: none;
  cursor:pointer;
}
.filterBox{
  padding: 0 10px 0 5px ;
  border: 1px solid rgba(0,0,0,.2) ;
  border-radius: 10px !important;
  background-color: white ;
  outline: none ;
  height: 30px !important;
  cursor: pointer;
}
.filterBtn{
  padding: 0 10px 0 5px ;
  font-weight: 700;
  border: 1px solid rgba(0,0,0,.2) ;
  border-radius: 10px !important;
  background-color: white ;
  outline: none ;
  height: 30px !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.filterBtnActive{
  background-color: #637df4;
  border: 1px solid #637df4 ;
  color: #ffffff;
  box-shadow: #637df4 1px 0.196px 11px;
  transition: all 0.2s ease-in-out;
}
.searchBox{
  display: flex;
  padding: 0 15px 0 0;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 10px;
}
.searchBox input{
  border: none;
  border-radius: 10px;
}
.searchBox img{
  cursor: pointer;
  width: 20px;
}
.countryflag{
  width:25px;
  height:25px;
  border-radius:50%;
}
.hamburger .menuOpen{
  transition: all .5s ease-in-out;
  opacity: 1;
  width: 62px;
}
.opacity-40{
  opacity:0.4;
}
.menu-close-icon {
  background: #607afc;
  
  
  padding: 5px;
  position: absolute;
  left: 62px;
  width: 26px;
  top: 0;
}
.menuIcon {
  display: none;
  /* background: #607afc; */
  padding: 2px;
  position: absolute;
  left: 0px;
  width: 25px;
  top: 0;
}
.menu-close-icon{
  display: none;
}
.actionContainer{
  display: flex;
  gap:20px
}
.headerContainer{
  padding-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  flex-wrap: wrap;
}
table td, table th{
  vertical-align: middle;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.tableContainer{
  justify-content: center;
  width: 100%;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
}
.auctionTable{
  width: 100%;
  margin-top: 10px;
  min-width: 1100px;
}
.auctionTable th, tr.table-row td{
  width: 10%;
  padding: 15px 5px;
  display: block;
  justify-content: center;
  align-self: center;
  text-align: center;
  word-break: break-word;
}
.tableHead{
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, .6);
  border-radius: 10px;
}
.table-row{
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, .1);
  border-bottom: 0;
  border-radius: 10px;
  border-radius: 0;
}
.table-row:last-child{
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}
thead .table-row:first-child{
  width: 100%;
}
tbody .table-row:first-child{
  margin-top: 20px;
  width: 100%;
}
tr:nth-child(even) {
  background-color: #fbfbfb;
}
.fieldLabel{
  font-weight: 600;
  font-size: 17px;
}
.actionBtn{
  border: 1px solid #637df4;
  border-radius:5px;
  background-color: white;
  color: #637df4;
  font-size: 18px;
  font-weight: 600;
  padding:0 20px;
  height: 35px;
  transition: all .35s ease-in-out;
}
.actionBtn:hover{
  background-color: #637df4;
  color: #ffffff;
  transition: all .35s ease-in-out;
}
.completeBtn{
  border: none;
  border-radius:5px;
  background-color: white;
  color: #637df4;
  font-size: 18px;
  font-weight: 600;
  padding:0 20px;
  height: 35px;
}
.deleteAction{
  width: 50px !important;
}
.deletedRow{
  background: rgba(239, 71, 71, 0.1) !important;
  cursor: not-allowed;
}
.revokedRow{
  background: rgb(0 0 0 / 10%) !important;
}
.deleteAction input[type="radio"]{
  margin-right: 0;
}
.checkbox-round {
  width: 0.9em;
  height: 0.9em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #607afc;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: #607afc;
}
.userIdName{
  min-width: 110px;
  display: flex !important;
}
.userIdName img {
  width: 30px;
  margin-left: 5px;
  margin-right: 5px;
  float: left;
  align-self: center;
  display: flex;
}
.sanctio-Details {
  display: flex;
  justify-content: space-around;
}
.status {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  display: inline-block;
  background: #D9D9D9;
}
.status-active {
  background: #65A252;
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
}
.status-end {
  background: #FF0707;
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
}
.status-pending {
  background: #D9D9D9;
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
}
.status-progress {
  background: #FBC623;
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
}
.status-registration-end {
  background: #fc5c00;
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
}
.status-registration {
  background: #607afc;
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
}
.lightColor{
  color: #979797;
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
}
.bike {
  height: 18px;
  width: 18px;
  background: #6C5DD3;
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
}
.car {
  height: 18px;
  width: 18px;
  background: #216FED;
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
}
.active-status{color: #7FC008;}
.end-status{color: #DB303F;}
.progress-status{color: #DB8C28;}
.registration-end-status{color: #fc5c00;}
.registration-status{color: #607afc;}

.auction-form input, .auction-form select {
  height: 40px !important;
}
.auction-form .form-group {
  margin-bottom: 20px;
}
.text-xs{
  font-size: 13px;
}
/* breadcrum */
.breadcrum {
  list-style-type: none;
  margin: 32px 0;
  padding: 0;
  display: flex;
}
.breadcrum li {
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
  color: #000000;
}
.breadcrum li:after {
  content: '/';
  font-size: 18px;
  padding-left: 2px;
  font-weight: 500;
  color: #617AF7;
}
.breadcrum li:nth-last-child(1):after {
    display: none;
}
.breadcrum li a {
  color: #617AF7;
  text-decoration: none;
}
/*Carousal icon control*/
.control-dots li {
  background: #C4C4C4 !important;
  box-shadow: 0 0 0 !important;
  width: 14px !important;
  height: 14px !important;
}
.control-dots li.selected {
  background: #5D78FF !important;
}
.carousel .slider-wrapper {
  padding: 30px 0;
}
.w-max{
  width:max-content;
}
.m-w-max{
  min-width:max-content;
}
.w-5{
  width: 5%;
}
.w-10{
  width: 10%;
}
.w-15{
  width: 15%;
}
.w-20{
  width: 20%;
}
.border-right{
  border-right: 1px solid #dddddd;
}
.border-left{
  border-left: 1px solid #dddddd;
}
.w-80{
  width: 80px !important;
}
.mw-400{
  min-width: 400px;
}
.sortingSvg{
  width: 10px;
  height: 15px;
  cursor: pointer;
}
.tabBtn button {
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  background: transparent;
  padding: 12px;
  margin: 0;
  cursor: pointer;
}
.tabBtn button:hover {
  background: transparent;
  color: #5d78ff;
}
.activeTab {
  border: 1px solid #5d78ff !important;
  color: #5d78ff;
}
.tabBtn button {       
  background: transparent;
  padding: 15px 35px;
  cursor: pointer;
  border:none;
}
.tabBtn{
   border: 1px solid #E9E9E9;
   border-radius: 6px;
}
.tabBtn button:hover {
  background: transparent;
  color: #5d78ff;
}
.activeTab {
  border: 1px solid #5d78ff !important;
  color: #5d78ff;
  border-radius: 5px;
}
.countryIcon img {
  width: 20px;
  height: 20px;
}
textarea{
  min-height: 40px !important;
  height: auto;
}
/* custom scrollbar */
::-webkit-scrollbar-track{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  width: 8px;
}
::-webkit-scrollbar{
	width: 5px;
  height: 5px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb{
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #617afb;
  border: 1px solid #ffffff;
}
/* errors */
.error{
  margin-top: 3px;
  font-size: 13px;
  color: rgb(160, 0, 0);
}
/* react-confirm */
.react-confirm-alert-body h1 {
  font-size: 22px;
  padding: 0 0;
  width: 100%;
  display: block;
  margin-bottom: 20px;
}
.resultSearchOuter {
  position: relative;
}
.searchResult {
  position: absolute;
  top: 39px;
  padding: 0;
  border: 1px solid #cccccc;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  z-index: 99;
}
.searchResult li {
  padding: 3px 10px;
}
.searchResult li:hover {
  background: #f18458;
  color: #ffffff;
}
.disabled{
  cursor: not-allowed !important;
  pointer-events: all !important;
  opacity: 50%;
}
.disabled input{
  cursor: not-allowed !important;
}
.clear-both{
  clear: both;
}
.prod-id{
  margin: 10px 0;
}
.prod-id span {
  border: 1px solid #979797;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 14px;
}
.formControlAction {
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #5D78FF;
  border-radius: 4px;
  text-align: center;
  padding: 15px;
}
.formControlAction button {
  padding: 5px;
}
.formControlAction span{
  color: #5D78FF;
  word-break: break-all;
}
.imageContainer{
  display: flex;
}
.imageContainer span{
  cursor: pointer;
  color: white;
  font-size: 12px;
  line-height: 9px;
  height: 13px;
  width: 13px;
}
.addImage{
  border: 1px solid #5D78FF;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.uploadedImage{
  cursor: pointer;
}

.tableImage{
  width: 60px;
  height: auto;
}
.defaultRecord{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
}
.defaultRecord td{
  width: 100% !important;
}
.defaultRecord span{
  font-size: 18px;
  opacity: 50%;
}
.warningIcon{
  width:20px;
}
.actionBtns {
  border: 1px solid #637df4;
  border-radius: 5px;
  overflow: hidden;
}
.actionBtns .actionBtn {
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  font-weight: 500;
}
.height-0{
  height: 0px;
}
.height-auto{
  height: auto;
}
.actionBtns .actionBtn:nth-last-child(1) {
  border-top: 1px solid #637df4;
}
.actionBtns .actionBtn:nth-child(1) {
  border-top: none;
  font-weight: 600;
}
.backContainer{
  cursor: pointer;
  width: max-content;
}
.backButton{
  color: #868686;
}
.modal-backdrop {
  background-color: rgb(0 0 0 / 45%);
}
.btn-secondary {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
.custom-modal{
  opacity: 1;
}
.show.custom-modal{
  display: block;
  opacity: 1;
}
.show.custom-modal .modal {
  opacity: 1;
  display: block;
}
/* .auctionMenu:before {
  content: "\A";
  border-style: solid;
  border-width: 7px 12px 8px 0;
  border-color: transparent #ffffff transparent transparent;
  position: absolute;
  left: -12px;
  top: 8px;
} */
.imgTest {
  height: 130px !important;
}

.imgTest1 {
  height: 100px !important;
}
/* .crop-area {
  height: 310px;
  width: 100%;
  display: block;
  margin: 0 auto;
}
.crop-area img {
  max-height: 300px !important;
  height: auto;
  width: auto;
  margin: 0 auto;
}
.crop-dialog{
  height: 300px;
} */

.rdtDays{
  margin-top: 25px;
}

.rdt input{
  border: none;
  border-left: 1px solid #ced4da;
  border-radius: 0;
}

.fieldTitle {
  font-size: 12px;
  font-style: italic;
  font-weight: 200;
  color: rgb(151,151,151);
}

.warningText{
  color:red;
  font-style: italic;
}
 
.time-start svg {
  width: 10px;
  height: 15px;
  cursor: pointer;
}

.max-content-width{
  width:max-content !important;
}
.extract-report {
  font-weight: 600 !important;
  cursor: pointer !important;
  text-transform: none !important;
  color: black !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  height: 32px !important;
  background-color: white !important;
}
.extract-report:hover {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  outline: none !important;
}

@media(max-width: 1280px){
  .tabBtn button {
    padding: 15px 15px;
  }
}

@media(max-width: 1080px){
  h1 {
    font-size: 26px;
  }
  .pricevalue p {
    font-size: 20px !important;
  }

}

@media(max-width: 991px){
  .actionContainer {
    justify-content: center;
  }
  .headerContainer {
    display: block !important;
    justify-content: center;
  }
  .tabBtn {
    width: max-content;
    margin: 0 auto;
  } 
  .headerContainer .searchContainer {
    margin: 20px auto;
    max-width: 250px;
    width: 100%;
  }
  h1 {
    font-size: 22px;
    padding: 0 0;
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  h1 img {
   width: 25px;
  }
  .mainContent {
    margin: 0 30px 0 90px;
  }
  .three-dot{ 
    width: calc( 100vw - 120px ) !important; 
  }
}

@media(max-width: 767px){
  table td, table th {
    font-size: 14px;
  }
  .menuIcon {
    display: block;
  }
  .menu-close-icon{
    display: block;
  }
  .actionContainer {
    gap: 5px !important;
  }
  .headerContainer {
    justify-content: center !important;
    gap: 5px;
  }
  .mainContent {
    margin: 0 30px 0 30px;
  }
  .leftMenu{
    display: none;
  }
  .hamburger .menuClose{
    width: 0px;
    opacity: 1;
    left:10px;
    overflow: hidden;
    transition: all .5s ease-in-out;
  }  
  .menuIcon{
    margin-left: 8px;
    margin-top: 10px;
    cursor: pointer;
  }
  .auctionTable {
    margin-top: 20px;
  }
  .navigationContainer a img, .footerContainer img {
    width: 30px !important;
  }
   .tableHead th, tr.table-row td {
    padding: 5px 2px !important;
  }
  .sanctio-Details {
      display: block;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 15px;
  }
  .sanctio-Details {
    display: block;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 15px;
  }
  .companyForm-detail .form-group input {
    margin-bottom: 20px;
  }
  .auction-form .form-group {
      margin-bottom: 20px;
  }
  .react-confirm-alert-body{
    max-width: 400px;
    width: 100% !important;
  }
  .three-dot{ 
    width: calc( 100vw - 60px ) !important; 
  }
}

@media(max-width: 575px){  
  .radioOptions, .inputSwitchgroup {
    display: block !important;
    padding-left: 5px !important;
  }
  input[type="radio"] {
    transform: scale(1.5);
    margin-right: 0px;
  }
  .rightSwitchChild{
    width: 100% !important;
  }
  .tabBtn {
    margin-top: 20px;
    display: block !important;
  }
  .tabBtn button {
    padding: 15px 10px !important;
  }
}

@media(max-width: 480px){
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding: 0;
  }
  .tabBtn {
    border: none !important;
    width: 100%;
  }
  .tabBtn button {      
    border: 1px solid #E9E9E9 !important;
    border-radius: 6px;
    margin: 5px 0;
    padding: 8px 16px !important;
    width: 100%;
  }
  .top-header{
    position:relative;
  }
  .top-header .go-back{
    position:absolute;
    left:0px;
    top:5px;
  }
}
