.menuContainer{
    background-color:#607afc;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}
.menu {
    height: 100vh;
    position: fixed;
    width: 0;
    top: 0;
    z-index: 9;
}
.auctionMenu{
    position: absolute;
    left: 50px;
    top: 100px;
    color: black;
    width: 130px;
    background-color: #ffffff;
    padding: 2px 0;
    border-radius: 5px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
}
.hotdealsMenu {
    position: absolute;
    left: 50px;
    top: 105px;
    color: black;
    width: 150px;
    background-color: #ffffff;
    padding: 2px 0;
    border-radius: 5px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
}
.priceMenu{
    position: absolute;
    left: 50px;
    color: black;
    width: 130px;
    background-color: #ffffff;
    padding: 2px 0;
    border-radius: 5px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);  
}
.termsMenu{
    position: absolute;
    left: 50px;
    color: black;
    width: 130px;
    background-color: #ffffff;
    padding: 2px 0;
    border-radius: 5px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);  
}
.sliderMenu{
    position: absolute;
    left: 50px;
    color: black;
    width: 130px;
    background-color: #ffffff;
    padding: 2px 0;
    border-radius: 5px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);  
}
.profileMenu{
    position: absolute;
    left: 50px;
    color: black;
    width: 130px;
    background-color: #ffffff;
    padding: 2px 0;
    border-radius: 5px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
}
.forumMenu {
    position: absolute;
    left: 50px;
    bottom: 170px;
    color: black;
    width: 150px;
    background-color: #ffffff;
    padding: 2px 0;
    border-radius: 5px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
}
.settingMenu{
    position: absolute;
    left: 50px;
    color: black;
    width: 150px;
    background-color: #ffffff;
    padding: 2px 0;
    border-radius: 5px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
}
.auctionEvent{
    border-bottom: 1px solid rgba(0, 0, 0, .1);;
}
.hamburger {
    height: 26px;
    width: 26px;
}
.navigationContainer{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 310px);
    overflow: hidden auto;
}
.navigationContainer a img, .notificationIcon{
    max-width: 25px;
    width: auto;
    margin: 0 auto;
}
.footerContainer{
    display: flex;
    flex-direction: column;
}
.logo{
    display: flex;
    justify-content: center;
    margin: 40px 0;
}
.logo a{
    color: white;
    align-self: center;
    text-align: center;
}
.logo a img{
   max-width: 40px;
   width: auto;
   margin: 0 auto;
}
.menu a:hover{
    color: #ffffff;
    opacity: .8;
    /* transition: all .35s ease-in-out; */
}
.links{
    align-self: center;
    margin: 20px 0;
    color: white;
}
.auctionLinks{
    align-self: center;
    margin: 20px 0;
    text-decoration: none;
    color: black;
}
.auctionLinks :hover{
    color: #607afc;
    transition: all .35s ease-in-out;
}
.footerLinks{
    align-self: center;
    margin-top: 10px;
    margin-bottom: 15px;
    color: white;
    cursor: pointer;
}
.profileImage {
    height: 40px;
    width: 40px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    overflow: hidden;
}
.profileImage img {
    margin: 0 auto;
    display: flex;
    width: 38px;
    height: 38px;
    object-fit: cover;
}

select.form-control {
    -moz-appearance:none; 
    -webkit-appearance:none; 
    appearance:none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 4px;
  }
  .backbtn{
    cursor: pointer;  
    height: 15px;
  }
@media(min-width: 768px){
    .hamburger{
        display: none;
    }
    .menu {
        width: 62px;
    }
}

